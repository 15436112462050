/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from 'react'
import { adminRouteNames, adminAccountRouteNames, adminPricingRouteNames } from 'constant'
import { Icon } from 'components/common'
import { useTranslation } from 'react-i18next'
import { usePermission } from 'components/common/permission'
import NavigationItem from './navigation-item'

const Navigation = () => {
  const { t } = useTranslation()
  const data = useMemo(
    () => [
      // {
      //   label: t('dashboard'),
      //   permission: 'dashboard-access',
      //   items: [
      //     {
      //       label: t('dashboard'),
      //       path: '/admin/dashboard',
      //       icon: <Icon iconClass="dashboard" type="dark" size="18px" />,
      //       hasBadge: false,
      //       badgeCount: 0,
      //       items: [
      //         {
      //           label: t('sales'),
      //           path: '/admin/dashboard/sales'
      //         },
      //         {
      //           label: t('activity'),
      //           path: '/admin/dashboard/activity'
      //         }
      //       ]
      //     }
      //   ]
      // },
      {
        label: t('accounts'),
        permission: ['supplier-view', 'branch-view', 'customer-view'],
        items: [
          {
            label: t('suppliers'),
            path: `${adminRouteNames.ACCOUNT}${adminAccountRouteNames.SUPPLIERS}`,
            icon: <Icon iconClass="suppliers" type="dark" size="20px" />,
            permission: 'supplier-view',
            hasBadge: false,
            badgeCount: 0,
            disabled: false
          },
          {
            label: t('branches'),
            path: `${adminRouteNames.ACCOUNT}${adminAccountRouteNames.BRANCHES}`,
            icon: <Icon iconClass="branch" type="dark" size="18px" />,
            permission: ['branch-view', 'own_branch-view'],
            hasBadge: false,
            badgeCount: 0
          },
          {
            label: t('customers'),
            path: `${adminRouteNames.ACCOUNT}${adminAccountRouteNames.CUSTOMERS}`,
            icon: <Icon iconClass="customers" type="dark" size="18px" />,
            permission: 'customer-view',
            hasBadge: false,
            badgeCount: 0
          }
        ]
      },
      {
        label: t('pricing'),
        permission: ['pricing-view', 'currency-view'],
        items: [
          {
            label: t('pricing'),
            path: `${adminRouteNames.PRICING}${adminPricingRouteNames.PRICING}`,
            icon: <Icon iconClass="pricing" type="dark" size="18px" />,
            permission: 'pricing-view',
            hasBadge: false,
            badgeCount: 0,
            disabled: false
          },
          {
            label: t('currency'),
            path: `${adminRouteNames.PRICING}${adminPricingRouteNames.CURRENCY}`,
            icon: <Icon iconClass="currency" type="dark" size="18px" />,
            permission: 'currency-view',
            hasBadge: false,
            badgeCount: 0,
            disabled: false
          }
        ]
      },
      {
        label: t('marketing tools'),
        permission: 'marketing_tools-view',
        items: [
          {
            label: t('marketing tools'),
            path: `${adminRouteNames.MARKETING}/media-library`,
            icon: <Icon iconClass="media-horn" type="dark" size="14px" />,
            hasBadge: false,
            permission: 'marketing_tools-manage',
            badgeCount: 0
          },
          {
            label: t('campaigns'),
            path: `${adminRouteNames.MARKETING}/campaigns`,
            icon: <Icon iconClass="media-horn" type="dark" size="14px" />,
            permission: 'marketing_tools-view',
            hasBadge: false,
            badgeCount: 0
          }
        ]
      },
      {
        label: t('admin tools'),
        permission: 'system_settings-view',
        items: [
          {
            label: t('system settings'),
            path: `${adminRouteNames.SETTINGS}`,
            icon: <Icon iconClass="cog" type="dark" size="18px" />,
            hasBadge: false,
            permission: 'system_settings-view',
            badgeCount: 0
          }
        ]
      }
    ],
    []
  )

  const NavigationListItem = ({ label, items }) => (
    <li className="navigation__item">
      <div className="navigation__label">{label}</div>
      {items && items.length > 0 && (
        <ul>
          {items.map((item, index) => (
            <div key={index}>
              {(!item.permission || usePermission(item.permission)) && (
                <NavigationItem key={item.label} {...item} />
              )}
            </div>
          ))}
        </ul>
      )}
    </li>
  )

  return (
    <div className="navigation">
      {data && data.length > 0 && (
        <ul className="navigation__list">
          {data.map((item, index) => (
            <div key={index} className="w-full">
              {usePermission(item.permission) && (
                <NavigationListItem key={item.label} label={item.label} items={item.items} />
              )}
            </div>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Navigation
